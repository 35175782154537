import React from "react";

import { Modal, Button } from 'react-bootstrap';

const ModalCustom = ({
    show,
    handleClose,
    title,
    children,
    handleDenyOpportunity,
    typeButton,
    onClickEvent,
    actionBtnLabel,
    customClass,
    disabledSave= false,
    ...res
}) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg"
        >
            <Modal.Header closeButton>

                <Modal.Title>
                    {title}
                </Modal.Title>

            </Modal.Header>

            <Modal.Body>

                {children}

            </Modal.Body>

            <Modal.Footer className="p-0 mb-3 mx-3">
                <Button variant="secondary"
                    onClick={handleClose}>
                    CERRAR
                </Button>
                <Button
                    variant={typeButton}
                    type="button"
                    onClick={onClickEvent}
                    className={customClass}
                    disabled={disabledSave}
                >
                    {actionBtnLabel}
                </Button>
            </Modal.Footer>

        </Modal>
    )

}

export default ModalCustom;