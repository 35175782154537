import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  BsCheck,
  BsCheck2,
  BsCheckCircle,
  BsFillFileEarmarkTextFill,
  BsXCircle,
} from "react-icons/bs";

import useHttp from "../../hook/use-http";

import "./datatables.scss";
import { toast } from "react-toastify";

const ConfiguratorItemsList = ({ history }) => {
  // save the product after add and extra fields
  const [configuratorData, setConfiguratorData] = useState([]);
  const [amountItemsIntoList, setItemsIntoList] = useState(0);

  // this function will be used to request the server to login the user
  const { loading, request } = useHttp();

  // this function will be used to redirect to the product detail page
  const onClickRegisterTable = (id) => {
    history.push(`/configurator-items-detail/${id}`);
  };

  const enableRow = (id) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/suministros-list/enable/" + id,
          method: "POST",
          headers: myHeaders,
        },
        (response) => {
          if(response.error){
            toast('Ya existe otra base de datos activa, es necesario desactivarla primero, para poder activar esta base de datos.', { type: "error", autoClose: 10000 });
          }else {
            toast("Suministro activado", { type: "success", autoClose: 10000 });
            getListConfigurator();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const disableRow = (id) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/suministros-list/disable/" + id,
          method: "POST",
          headers: myHeaders,
        },
        () => {
          toast("Suministro desactivado", {
            type: "success",
            autoClose: 10000,
          });
          getListConfigurator();
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = (item) => {
    if (item.estado.toLowerCase() === "activo") {
      disableRow(item.id);
    } else {
      enableRow(item.id);
    }
  };
  
  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    let hour = `${d.getHours()}`;
    let minute = `${d.getMinutes()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    if (hour.length < 2) hour = `0${hour}`;
    if (minute.length < 2) minute = `0${minute}`;

    return [year, month, day].join("-") + " " + [hour, minute].join(":");
  };

  // this function will be process the result of the method getListConfigurator
  const handleGetListConfiguratorDataResponse = (data) => {
    if (data.status === 401) {
      // show a error message here

      if (data.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(data.message, { type: "error", autoClose: 10000 });
    } else {
      const result = data.map((item, index) => {
        item.index = index + 1;

        item.createdDateFormatted = formatDate(item.createdDate);
        // item.image = <img src={`${process.env.REACT_APP_S3_URL}${item.path}`} alt='configurator' className="img-thumbnail w-50-px" />
        // item.statusES = item.status ? 'Activo' : 'Inactivo'
        item.change = (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button
              className="btn btn-info"
              onClick={() => onClickRegisterTable(item.id)}
              style={{ display: "flex", backgroundColor: "#3af23a" }}
            >
              <div className="icon-eyes">
                <BsFillFileEarmarkTextFill />
              </div>
            </button>
            <button
              className="btn btn-info"
              onClick={() => handleStatus(item)}
              style={{
                display: "flex",
                backgroundColor: '#3af23a'
              }}
            >
              <div className="icon-eyes">
                {" "}
                {item.estado.toLowerCase() === "activo" ? (
                  <BsXCircle />
                ) : (
                  <BsCheckCircle />
                )}
              </div>
            </button>
          </div>
        );
        return item;
      });

      console.log(result);
      setItemsIntoList(result.length);
      setConfiguratorData(result);
    }
  };

  // this function will be used to request the data from the server
  const getListConfigurator = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/suministros-list/list",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListConfiguratorDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListConfigurator();
  }, []);

  const columns = [
    {
      dataField: "index",
      text: "ID",
      sort: true,
    },
    {
      dataField: "descripcion",
      text: "DESCRIPCION",
      sort: true,
    },
    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
    },
    {
      dataField: "createdDateFormatted",
      text: "FECHA CREACIÓN",
      sort: true,
    },
    {
      dataField: "change",
      text: "DETALLES",
      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: amountItemsIntoList, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={configuratorData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={configuratorData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="table-responsive"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorItemsList;
