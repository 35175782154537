import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as XLSX from "xlsx";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import Spreadsheet from "react-spreadsheet";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

const ConfiguratorItemsRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const printers = ["SFP", "MFP", "MPF A3", "MFP A3"];
  const columnLabels = [
    "NUMERO DE PARTE",
    "GRUPO",
    "TIPO",
    "MODELO",
    "DESCRIPCION",
    "RENDIMIENTO",
    "PRECIO LOCAL US$ CANAL",
    "SUBGRUPO"
  ];

  // get the data with redux dispatch
  // this will be used to control de component modal

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    descripcion: null,
  });

  const handleCreateBannerDataResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push(`/configurator-items-created`);
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      myHeaders.append("Content-Type", "application/json");
      request(
        {
          url: `/auth/v1/configurador/suministros/register-all`,
          method: "POST",
          headers: myHeaders,
          body: {
            parent: { descripcion: form.descripcion },
            configurationSupplies: dataOriginal,
          },
        },
        handleCreateBannerDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      jsonData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          let fixed_key = key
            .toLowerCase()
            .trim()
            .replace(/ /g, "_")
            .replace("$", "");

          if(!isNaN(item[key])){
            item[key] = (+item[key]).toFixed(2);
          }
          item[fixed_key] = item[key] + '';
          delete item[key];
        });
      });
      let auxData = [];
      let numero_de_parte_padre = null;
      jsonData.forEach((item) => {
        console.log("item", item);
        item.numero_de_parte = "" + item.numero_de_parte;
        if (printers.includes(item.grupo)) {
          item.numero_de_parte_padre = null;
          numero_de_parte_padre = item.numero_de_parte;
        } else {
          item.numero_de_parte_padre = numero_de_parte_padre;
          if (item.tipo === "Mono Laser") {
            if (item.rendimiento && item.grupo.substring(0, 8) === "Supplies") {
              item.volumen = "black";
            } else {
              item.volumen = "total";
            }
          } else if (item.tipo === "Color Laser") {
            const lastTwoChart = item.numero_de_parte.slice(-2);
            const color = ["C0", "M0", "Y0"];
            const black = ["K0"];
            if (
              item.rendimiento &&
              color.includes(lastTwoChart) &&
              item.grupo.substring(0, 8) === "Supplies"
            ) {
              item.volumen = "color";
            } else if (
              item.rendimiento &&
              black.includes(lastTwoChart) &&
              item.grupo.substring(0, 8) === "Supplies"
            ) {
              item.volumen = "black";
            } else {
              item.volumen = "total";
            }
          }
        }
      });
      jsonData.forEach((item) => {
        let auxRow = [
          { value: item.numero_de_parte, readOnly: true },
          { value: item.grupo, readOnly: true },
          { value: item.tipo, readOnly: true },
          { value: item.modelo, readOnly: true },
          { value: item.descripcion, readOnly: true },
          { value: item.rendimiento, readOnly: true },
          { value: item.precio_local_us_canal, readOnly: true },
          { value: item.subgrupo, readOnly: true }
        ];

        auxData.push(auxRow);
      });

      let dataOriginalAux = [];
      jsonData.forEach((item) => {
        let auxRow = {
          numeroParte: item.numero_de_parte || "",
          grupo: item.grupo || "",
          tipo: item.tipo || "",
          modelo: item.modelo || "",
          descripcion: item.descripcion || "",
          rendimiento: item.rendimiento || "",
          impresora: item.numero_de_parte_padre ? "false" : "true",
          precioReferencialUnitario: (item.precio_local_us_canal && item.precio_local_us_canal.replace(',','') )  || 0,
          numeroPartePadre: item.numero_de_parte_padre || "",
          volumen: item.volumen || "",
          subgrupo: item.subgrupo || ""
        };
        dataOriginalAux.push(auxRow);
      });
      setDataOriginal(dataOriginalAux);
      setData(auxData);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title">
                      CARGAR LISTA COMPLETA DEL CONFIGURADOR
                    </h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-note"
                          >
                            Descripción
                          </Label>
                          <br />
                          <AvField
                            name="descripcion"
                            type="text"
                            errorMessage="Ingrese la descripción"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-note"
                            value={form.descripcion}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="item-configurator-upload"
                          >
                            Cargar Excel
                          </Label>
                          <br />
                          <AvField
                            accept=".xlsx, .xls"
                            name="excel"
                            type="file"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="item-configurator-create-excel"
                            onChange={handleFileUpload}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <h4 className="card-title">PREVISUALIZAR LOS DATOS</h4>
                      </Col>
                      <Col md="12">
                        <hr />
                        <p>
                          <b>Total impresoras:</b>{" "}
                          {dataOriginal.reduce((acc, item) => {
                            if (!item.numeroPartePadre) acc++;
                            return acc;
                          }, 0)}{" "}
                        </p>
                        <br />
                        <Spreadsheet data={data} columnLabels={columnLabels} />
                      </Col>
                    </Row>
                    <div className="text-right">
                      <br />

                      <Button
                        className="btn btn-primary-lexmark"
                        type="submit"
                        disabled={dataOriginal.length === 0 || !form.descripcion}
                      >
                        Crear
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorItemsRegister;
