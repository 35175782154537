import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { saveAs } from "file-saver";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { LuDownload, LuPrinter } from "react-icons/lu";

import useHttp from "../../hook/use-http";
import * as XLSX from "xlsx";

import "./datatables.scss";
import { toast } from "react-toastify";
import { act } from "react";

const ConfiguratorListAdmin = ({ history }) => {
  // save the product after add and extra fields
  const [configuratorData, setConfiguratorData] = useState([]);
  const [amountItemsIntoList, setItemsIntoList] = useState(0);

  // this function will be used to request the server to login the user
  const { loading, request } = useHttp();

  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    let hour = `${d.getHours()}`;
    let minute = `${d.getMinutes()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    if (hour.length < 2) hour = `0${hour}`;
    if (minute.length < 2) minute = `0${minute}`;

    return [year, month, day].join("-") + " " + [hour, minute].join(":");
  };

  // this function will be process the result of the method getListConfigurator
  const handleGetListConfiguratorDataResponse = (data) => {
    console.log("data", data);
    if (data.status === 401) {
      // show a error message here

      if (data.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(data.message, { type: "error", autoClose: 10000 });
    } else {
      const result = data.map((item, index) => {
        item.index =  item.codigoConfiguracion;
        item.suministrosLength = item.suministros.length;
        item.softwareLength = item.software.length;
        item.canalName = item.canal.razonSocial;
        item.createdDateFormatted = formatDate(item.createdDate);
        item.totalUS = item.total
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(/\$/g, "$ ");
        item.change = (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button
              className="btn btn-info"
              onClick={() => downloadPdf(item.id)}
              style={{ display: "flex", backgroundColor: "#3af23a" }}
            >
              <div className="icon-eyes">
                <LuPrinter />
              </div>
            </button>

            <button
              className="btn btn-info"
              onClick={() => getProfileConfigurator(item.id)}
              style={{ display: "flex", backgroundColor: "#3af23a" }}
            >
              <div className="icon-eyes">
                <LuDownload />
              </div>
            </button>
          </div>
        );
        return item;
      });

      setItemsIntoList(result.length);
      setConfiguratorData(result);
    }
  };

  const getProfileConfigurator = (id) => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        request(
          {
            url: `/auth/v1/configurador/list/id/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          downloadExcel
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  }; 


  const downloadExcel = (dataConfig) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    request({
        url: '/auth/v1/configurador/notes/list',
        method: 'GET',
        headers: myHeaders,
    }, (notesData) => {
      const pdfData = {
        configurador: {
          title: "CONFIGURADOR LEXMARK",
          date: "Fecha: " + getDate(dataConfig.createdDate),
          numeroCotizacion: "Número de cotización: " + dataConfig.codigoConfiguracion,
          reseller: "Nombre del reseller: " + dataConfig.canal.contactoPrincipal.nombreContacto,
          client: "Datos cliente: " + dataConfig.entidad,
        },
        products: [
          [
            "PN",
            "Subgrupo",
            "Grupo",
            "Tipo",
            "Descripción",
            "Modelo",
            "Rendimiento",
            "Cantidad",
            "Precio Unitario Ref",
            "Precio Total Ref",
          ]
        ],
        summary: [
          ["Resumen"],
          [
            "Subgrupo",
            "Grupo",
            "PN",
            "Descripción",
            "Modelo",
            "Cantidad",
            "Precio Unitario Ref",
            "Precio Total Ref",
          ],
        ],
        totals: {
          subtotal: "Subtotal: $3591.87",
        },
        notes: [
          "* Este es un costo de click referencial basado solo en suministros.",
          '',
          "Notas",
          '',
          ...notesData.sort((a, b) => +a.position - +b.position).map((item) => item.descripcion),
    
        ],
      };
      let subtotal = 0;
      dataConfig.suministros.forEach((item, index) => {
        if(index != 0 && item.impresora){
            pdfData.products.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
        }
        subtotal+= +item.precioReferencialTotal;
    
        pdfData.products.push([
            item.numeroParte,
            item.subgrupo,
            item.grupo,
            item.tipo,
            item.descripcion,
            item.modelo,
            item.rendimiento,
            item.cantidad,
            item.precioReferencialUnitario,
            item.precioReferencialTotal,
          ])
    
          if((index + 1 ) === dataConfig.suministros.length){
            pdfData.products.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
          }
        
    
      })
      subtotal = 0;
      dataConfig.software.forEach((item, index) => {
       
        subtotal+= +item.precioReferencialTotal;
    
        pdfData.products.push([
            item.numeroParte,
            'Software',
            'Software',
            item.tipo,
            item.descripcion,
            item.modelo,
            item.rendimiento,
            item.cantidad,
            item.precioReferencialUnitario,
            item.precioReferencialTotal,
          ])
    
          if((index + 1 ) === dataConfig.software.length){
            pdfData.products.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
          }
        
    
      })
    
    
      pdfData.products.push([
        'Total',
        "",
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        dataConfig.total,
      ])
      const componentsOrderBySubgroup = [...dataConfig.suministros].sort((a, b) => a.subgrupo.localeCompare(b.subgrupo))
      let actualGroup = ''
      componentsOrderBySubgroup.forEach((item, index) => {
        if(actualGroup === '') actualGroup = item.subgrupo;
    
        if(index != 0 && actualGroup !== item.subgrupo){
            pdfData.summary.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
            actualGroup = item.subgrupo
        }
        subtotal+= +item.precioReferencialTotal;
        pdfData.summary.push([
            item.subgrupo,
            item.grupo,
            item.numeroParte,
            item.descripcion,
            item.modelo,
            item.cantidad,
            item.precioReferencialUnitario,
            item.precioReferencialTotal,
        ])
        if((index + 1 ) === dataConfig.suministros.length){
            pdfData.summary.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
          }
      })
      dataConfig.software.forEach((item, index) => {
        pdfData.summary.push([
            'Software',
            'Software',
            item.numeroParte,
            item.descripcion,
            item.modelo,
            item.cantidad,
            item.precioReferencialUnitario,
            item.precioReferencialTotal,
        ])
    
        subtotal+= +item.precioReferencialTotal;
        if((index + 1 ) === dataConfig.software.length){
            pdfData.summary.push([
                'Subtotal',
                "",
                "",
                '',
                '',
                '',
                '',
                subtotal,
              ])
            subtotal = 0;
          }
        
      })
    
    
      pdfData.summary.push([
        'Total',
        "",
        '',
        '',
        '',
        '',
        '',
        dataConfig.total,
      ])
    
    
    
        // Crear una nueva hoja de cálculo
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]); // Inicia con una celda vacía para el padding
    
        let currentRow = 1; // Comenzar en la fila 1 para simular padding
    
        // Función para agregar datos a la hoja de cálculo con un desplazamiento
        const addDataToSheet = (data, startRow, startCol = 1) => {
          data.forEach((row, rowIndex) => {
            const excelRowIndex = startRow + rowIndex;
            row.forEach((cell, colIndex) => {
              XLSX.utils.sheet_add_aoa(ws, [[cell]], {
                origin: { r: excelRowIndex, c: startCol + colIndex },
              });
            });
          });
        };
    
        // Calcular el número máximo de columnas basado en el contenido
        const maxColumns = Math.max(
          ...pdfData.products.map((row) => row.length),
          pdfData.summary[0].length
        );
    
        // Agregar título combinando y centrando sobre todas las columnas
        addDataToSheet(
          [
            [""], // Padding para la primera fila
            [pdfData.configurador.title],
            [""], // Fila vacía para separar secciones
          ],
          currentRow
        );
        ws["!merges"] = [
          { s: { r: currentRow, c: 1 }, e: { r: currentRow, c: maxColumns } },
        ]; // Combinar y centrar
    
        // Inicializar la celda para asegurarse de que existe antes de aplicar estilo
        if (!ws[`B${currentRow + 1}`]) {
          ws[`B${currentRow + 1}`] = { t: "s", v: "" };
        }
        ws[`B${currentRow + 1}`].s = {
          alignment: { horizontal: "center" },
          font: { bold: true, sz: 14 },
          fill: { fgColor: { rgb: "FFD700" } },
        }; // Centrar y formato
        currentRow += 3; // Ajustar la fila actual
    
        // Agregar detalles del configurador
        addDataToSheet(
          [
            [pdfData.configurador.date],
            [pdfData.configurador.numeroCotizacion],
            [pdfData.configurador.reseller],
            [pdfData.configurador.client],
            [""], // Fila vacía para separar secciones
          ],
          currentRow
        );
        ws["!merges"].push(
          { s: { r: currentRow, c: 1 }, e: { r: currentRow, c: maxColumns } },
          {
            s: { r: currentRow + 1, c: 1 },
            e: { r: currentRow + 1, c: maxColumns },
          },
          {
            s: { r: currentRow + 2, c: 1 },
            e: { r: currentRow + 2, c: maxColumns },
          }
        );
        // Inicializar celdas para aplicar estilos
        if (!ws[`B${currentRow}`]) ws[`B${currentRow}`] = { t: "s", v: "" };
        if (!ws[`B${currentRow + 1}`]) ws[`B${currentRow + 1}`] = { t: "s", v: "" };
        if (!ws[`B${currentRow + 2}`]) ws[`B${currentRow + 2}`] = { t: "s", v: "" };
        ws[`B${currentRow}`].s =
          ws[`B${currentRow + 1}`].s =
          ws[`B${currentRow + 2}`].s =
            {
              alignment: { horizontal: "center" },
              font: { bold: true },
              fill: { fgColor: { rgb: "FFFFFF" } },
            }; // Centrando y formato
        currentRow += 4;
    
        // Agregar descripción de los productos
        addDataToSheet(
          [
            ["Descripción de los productos"],
            ...pdfData.products,
            [""], // Fila vacía para separar secciones
          ],
          currentRow
        );
        ws["!merges"].push({
          s: { r: currentRow, c: 1 },
          e: { r: currentRow, c: maxColumns },
        });
        // Inicializar celda para aplicar estilo
        if (!ws[`B${currentRow + 1}`]) ws[`B${currentRow + 1}`] = { t: "s", v: "" };
        ws[`B${currentRow + 1}`].s = {
          alignment: { horizontal: "center" },
          font: { bold: true },
          fill: { fgColor: { rgb: "FFD700" } },
        }; // Centrando y formato
        currentRow += pdfData.products.length + 2;
    
        // Agregar resumen
        addDataToSheet(pdfData.summary, currentRow);
        ws["!merges"].push({
          s: { r: currentRow, c: 1 },
          e: { r: currentRow, c: maxColumns },
        });
        // Inicializar celda para aplicar estilo
        if (!ws[`B${currentRow}`]) ws[`B${currentRow}`] = { t: "s", v: "" };
        ws[`B${currentRow}`].s = {
          alignment: { horizontal: "center" },
          font: { bold: true },
          fill: { fgColor: { rgb: "FFD700" } },
        }; // Centrando y formato
        currentRow += pdfData.summary.length + 1;
    
    
        // Agregar notas
        pdfData.notes.forEach((note, index) => {
          XLSX.utils.sheet_add_aoa(ws, [[note]], {
            origin: { r: currentRow + index, c: 1 },
          });
          ws["!merges"].push({
            s: { r: currentRow + index, c: 1 },
            e: { r: currentRow + index, c: maxColumns },
          });
          // Inicializar celda para aplicar estilo
          if (!ws[`B${currentRow + index + 1}`])
            ws[`B${currentRow + index + 1}`] = { t: "s", v: "" };
          ws[`B${currentRow + index + 1}`].s = {
            alignment: { horizontal: "center" },
            font: { italic: true },
            fill: { fgColor: { rgb: "E0E0E0" } },
          }; // Centrando y formato
        });
    
        // Ajustar el ancho de las columnas al contenido
        const columnWidths = [];
        for (let i = 0; i <= maxColumns; i++) {
          columnWidths.push({ wch: 20 }); // Ajustar el ancho de cada columna. El valor '20' es un estimado, puedes ajustarlo según sea necesario.
        }
        ws["!cols"] = columnWidths;
    
        // Agregar la hoja al libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, "Configurador");
    
        // Guardar el archivo Excel
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, "Configurador_Lexmark.xlsx");
    })

  };

  const handleDownloadPdfResponse = async (response) => {
    if (response.status === 401) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      let yourDate = new Date();

      const result = await response.blob();
      const blob = new Blob([result], { type: "application/pdf" });
      saveAs(blob, `configurador_${yourDate.toISOString().split("T")[0]}.pdf`);
    }
  };

  const downloadPdf = (id) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/download/" + id,
          method: "GET",
          headers: myHeaders,
        },
        handleDownloadPdfResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  // this function will be used to request the data from the server
  const getListConfigurator = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/list-all",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListConfiguratorDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListConfigurator();
  }, []);

  const columns = [
    {
      dataField: "index",
      text: "ID",
      sort: true,
    },
    {
      dataField: "canalName",
      text: "CANAL",
      sort: true,
    },
    {
      dataField: "entidad",
      text: "ENTIDAD",
      sort: true,
    },
    {
      dataField: "oportunidadId",
      text: "OPORTUNIDAD",
      sort: true,
    },
    {
      dataField: "suministrosLength",
      text: "SUMINISTROS",
      sort: true,
    },
    {
      dataField: "softwareLength",
      text: "SOFTWARE",
      sort: true,
    },
    {
      dataField: "totalUS",
      text: "TOTAL",
      sort: true,
    },
    {
      dataField: "createdDateFormatted",
      text: "FECHA CREACIÓN",
      sort: true,
    },
    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
    } /* {
            dataField: 'acuerdo_comercial',
            text: 'ACUERDO COMERCIAL',
            sort: true
        }, {
            dataField: 'ruc_documento',
            text: 'RUC DOCUMENTO',
            sort: true
        }, */,
    {
      dataField: "change",
      text: "DETALLES",
      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: amountItemsIntoList, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={configuratorData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={configuratorData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="table-responsive"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorListAdmin;
