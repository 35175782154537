import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import useHttp from "../../hook/use-http";

const DigitalSignSwitch = () => {
  const { loading, error, request } = useHttp();
  const [isDigitalSignatureEnabled, setIsDigitalSignatureEnabled] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false)
  // Obtener la configuración inicial
  const getConfigurationSign = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    request(
      {
        url: `/auth/v1/configurador/sign/list`,
        method: "GET",
        headers: myHeaders,
      },
      handleGetResponse
    );
  };

  // Manejar la respuesta de obtener configuración
  const handleGetResponse = (response) => {
    if (response && Array.isArray(response)) {
      const item = response.find((item) => item.key === "Sign");
      if (item) {
        setIsDigitalSignatureEnabled(item.active);
      }
    } else {
      console.error("Error al obtener la configuración.");
    }
  };

  // Actualizar la configuración
  const updateConfigurationSign = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    request(
      {
        url: `/auth/v1/configurador/sign/switch`,
        method: "PUT",
        headers: myHeaders,
      },
      handleUpdateResponse
    );
  };

  // Manejar la respuesta de actualización
  const handleUpdateResponse = (response) => {
    if (response.status === 401) {
        // show a error message here
      } else {
        try {
            setIsDigitalSignatureEnabled(response.active);

        } catch (error) {
          console.log(error);
        }
      }
  };

  // Manejar el cambio del switch
  const handleToggle = () => {
    updateConfigurationSign();
  };


      useEffect(() => {
          const role = localStorage.getItem('role')
          if (role === 'ROLE_ADMIN') {
              setIsAdmin(true)
          }
      })

  useEffect(() => {
    getConfigurationSign();
  }, []);

  return (
    <>
    {
      isAdmin && (
        <Row className="d-flex justify-content-center">
        <Col xl="6">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 text-left">FIRMAS DIGITALES</h4>
              <p className="mb-4 text-left">
                Cuando esta opción está activada, los certificados descargados
                se firmarán digitalmente. Si está desactivada, se usará una
                firma precargada.
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <span className="me-3">
                  <strong>Estado:</strong>{" "}
                  {isDigitalSignatureEnabled ? "Activado" : "Desactivado"}
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isDigitalSignatureEnabled}
                    onChange={handleToggle} // Solo llamamos a la función de actualización
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {loading && <p>Cargando...</p>}
              {error && <p>Error al realizar la operación.</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      )
    }
      
    </>
  );
};

export default DigitalSignSwitch;
