import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import ModalCustom from "../../components/Modal";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */

/* This array represent the list of type of channel */

const ConfiguratorRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  const [items, setItems] = useState([]);
  const [itemsDescription, setItemsDescription] = useState([]);
  const [itemsPreview, setItemsPreview] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [softwareList, setSoftwareList] = useState({});
  const [softwareSelectedLPM, setSoftwareSelectedLPM] = useState({});
  const [softwareSelectedSMSA, setSoftwareSelectedSMSA] = useState({});

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  const [entities, setEntities] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [nameOpportunity, setNameOpportunity] = useState();
  const [nameEntity, setNameEntity] = useState();

  // this will be used to control de component modal
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [volume, setVolume] = useState(0);
  const [searchPrinter, setSearchPrinter] = useState("");
  const [printers, setPrinters] = useState([]);

  let profile = useSelector((state) => state.profileReducer.profile);

  const handleClose = () => {
    if (
      itemSelected &&
      Object.keys(itemSelected).length > 0 &&
      itemSelected.components &&
      itemSelected.components.filter((child) => child.selected)
    ) {
      itemSelected.components.forEach((item) => {
        item.selected = false;
      });
      setItemSelected(itemSelected);
    }
    setItemSelected({});
    setSearchPrinter("");
    setVolume(0);
    setPrinters([]);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleRedirectToListWhenTheConfiguratorIsCreated = () => {
    history.push(`/configurator-list`);
  };

  useEffect(() => {
    getEntities();
    getSoftware();
    // const sortEntities = entities.sort((a, b) => {
    //     return a.entidad < b.entidad ? -1 : a.entidad > b.entidad ? 1 : 0;
    // })
    //setEntities([{ "entidad": "" }, ...sortEntities])
  }, []);
  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    partNumber: null,
    group: null,
    type: null,
    model: null,
    description: null,
    performance: null,
    localPrice: null,
    status: "Activo",
  });

  const columns = [
    {
      dataField: "partNumber",
      text: "Num. DE PARTE",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      sort: true,
      isNumberWithouthDecimal: true,
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Local US",
      sort: true,
    },
  ];

  const columnsParent = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "monthVolume",
      text: "Volumen B/N",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "monthVolumeColor",
      text: "Volumen Color",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "quantity",
      text: "Cantidad",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Total Referencial",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      className: "w-100-px",
    },
  ];

  const columnsPreviewParent = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "quantityNumber",
      text: "Cantidad",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Total Referencial",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
  ];

  const columnsPreview = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "quantityNumber",
      text: "Cantidad",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Total Referencial",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
  ];

  const columnsSoftware = [
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Cantidad de equipos",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "basePrice",
      text: "Base Enterprise Price MFP",
      isNumber: true,
      sort: true,
    },
    {
      dataField: "totalPrice",
      text: "Precio Total Referencial",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      className: "w-100-px",
    },
  ];

  /* Create a function to validate each input is not empty */
  const validateInput = (input) => {
    return input !== "";
  };

  /* Create a function to validate each input the form */
  const validateForm = () => {
    return (
      validateInput(form.title) &&
      validateInput(form.description) &&
      validateInput(form.type)
    );
  };
  const compararPorEntidad = (a, b) => {
    // Utiliza toUpperCase() para hacer la comparación insensible a mayúsculas y minúsculas
    const entidadA = a.entidad.toUpperCase();
    const entidadB = b.entidad.toUpperCase();

    if (entidadA < entidadB) {
      return -1;
    }
    if (entidadA > entidadB) {
      return 1;
    }
    return 0; // Son iguales
  };

  // this function will be process the result of the method getListEntity
  const handleGetListSoftwareDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      setSoftwareList(
        products.map((software) => ({
          partNumber: software.numeroParte,
          description: software.descripcion,
          basePrice: software.precioReferencialUnitario,
          type: software.tipo,
        }))
      );
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getSoftware = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/software/list",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListSoftwareDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  // this function will be process the result of the method getListEntity
  const handleGetListEntityDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      products.sort(compararPorEntidad);
      setEntities([...products]);
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getEntities = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/entity/active",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetOpportunities = (products) => {
    if(!products) return;
    if(products.status === 400){
      toast(products.message, { type: "info", autoClose: 10000 });
    }
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      const result = [];

      // add and extra field to products
      products.map((item, index) => {
        item.id = index + 1;

        let d = new Date(item.createdDate);
        let dformat =
          [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
          " " +
          [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

        item.createdDate = dformat;

        item.descripcion =
          item.descripcion?.length > 20
            ? item.descripcion?.slice(0, 20) + "..."
            : item.descripcion;

        // item.checkbox = <input type="checkbox" onClick={() => handleSelectOpportunity(item)} />

        result.push(item);
      });

      const res = result.sort((a, b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });

      setOpportunities(res);
    }
  };
  const getOpportunities = (entidad) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      setOpportunities([])

      //  url: `/auth/oportunidad/list/estado?estado=PENDIENTE`,
      request(
        {
          url: `/auth/oportunidad/list/entidad?entidad=${entidad}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetOpportunities
      );
    } catch (error) {
      console.log(error);
    }
  };

  const convertToBase64 = (file, f) => {
    try {
      console.log("file", file);
      //console.log('f', f);

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      console.log(base64);
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.timeLog(reader)
          base64 = reader?.result;
          console.log(base64);
          setForm({ ...form, preview: base64, file: file[0] });
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleCreateConfiguratorDataResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      localStorage.setItem("code-created", response.codigoConfiguracion);
      history.push(`/configurator-created`);
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      const suministros = [];
      let position = 0;

      if (itemsPreview.filter((item) => item.group !== "Software").length > 0) {
        itemsPreview
          .filter((item) => item.group !== "Software")
          .forEach((item) => {
            item.children.forEach((child) => {
              if (!child.partNumberParent) {
                suministros.push({
                  numeroParte: child.partNumber,
                  cantidad: child.quantityNumber,
                  position,
                });
              } else {
                suministros.push({
                  numeroParte: child.partNumber,
                  cantidad: child.quantityNumber,
                  position,
                  numeroPartePadre: child.partNumberParent,
                });
              }
              position++;
            });
          });
      }

      const software = [];
      let positionSoftware = 0;
      const softwareItem = itemsPreview.find(
        (item) => item.group === "Software"
      );
      if (softwareItem) {
        softwareItem.children.forEach((child) => {
          software.push({
            numeroParte: child.partNumber,
            cantidad: child.quantityNumber,
            position: positionSoftware,
          });
          positionSoftware++;
        });
      }

      const body = {
        ruc: profile.ruc,
        oportunidadId: nameOpportunity,
        entidad: nameEntity,
        suministros: suministros,
        software: software,
      };

      request(
        {
          url: `/auth/v1/configurador/register`,
          method: "POST",
          headers: myHeaders,
          body,
        },
        handleCreateConfiguratorDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectSupplies = (partNumber) => {
    const aux = { ...itemSelected };
    const supply = itemSelected.components.find(
      (item) => item.partNumber === partNumber
    );
    supply.selected = supply.selected ? false : true;
    setItemSelected(aux);
  };

  const handleQuantityChange = (indexParent, index, value) => {
    const aux = [...items];
    aux[indexParent].components[index].quantityNumber = +value;
    aux[indexParent].components[index].totalPrice = (
      +value * +aux[indexParent].components[index].priceLocalUS
    ).toFixed(2);
    aux[indexParent].subTotal = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +aux[indexParent].totalPrice
    ).toFixed(2);
    setItems(aux);
  };

  const handleQuantityParentChange = (indexParent, value) => {
    const aux = [...items];
    aux[indexParent].quantityNumber = +value;

    aux[indexParent].components.forEach((child) => {
      if (child.performance) {
        let monthVolume = +aux[indexParent].monthVolume;
        let monthVolumeColor = +aux[indexParent].monthVolumeColor;
        let totalVolumenBlack = monthVolume * aux[indexParent].quantityNumber;
        let totalVolumeColor =
          monthVolumeColor * aux[indexParent].quantityNumber;
        let totalVolume = totalVolumeColor + totalVolumenBlack;
        let performance = +child.performance;
        if (child.volumen === "black") {
          child.quantityNumber = Math.ceil(totalVolumenBlack / performance);
        } else if (child.volumen === "color") {
          child.quantityNumber = Math.ceil(totalVolumeColor / performance);
        } else if (child.volumen === "total") {
          child.quantityNumber = Math.ceil(totalVolume / performance);
        }
        child.totalPrice = +(child.quantityNumber || 1) * +child.priceLocalUS;
      }
    });

    aux[indexParent].totalPrice = (
      +value * +aux[indexParent].priceLocalUS
    ).toFixed(2);
    aux[indexParent].subTotal = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +aux[indexParent].totalPrice
    ).toFixed(2);
    setItems(aux);
  };

  const deleteParent = (index) => {
    let auxItems = [...items];
    auxItems.splice(index, 1);
    // auxItems[index].subTotal = (
    //   auxItems[index].components.reduce(
    //     (acc, item) => acc + +item.totalPrice,
    //     0
    //   ) + +auxItems[index].totalPrice
    // ).toFixed(2);
    setItems(auxItems);
  };

  const deleteChildren = (indexParent, indexChild) => {
    let auxItems = [...items];
    auxItems[indexParent].components.splice(indexChild, 1);

    auxItems[indexParent].subTotal = (
      auxItems[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +auxItems[indexParent].totalPrice
    ).toFixed(2);
    setItems(auxItems);
  };

  const getDateToday = () => {
    let yourDate = new Date();
    return yourDate.toISOString().split("T")[0];
  };

  const handleStep2 = () => {
    setStep(1);
  };

  const handlePreview = () => {
    const previewArr = [];
    const itemsDescriptionArr = [];
    console.log(items)
    items.forEach((item) => {
      itemsDescriptionArr.push(item);
      if (!previewArr.find((prev) => prev.subgroup === item.subgroup)) {
        previewArr.push({ subgroup: item.subgroup, children: [] });
      }
      previewArr
        .find((prev) => prev.subgroup === item.subgroup)
        .children.push(item);
      item.components.forEach((child) => {
        if (!previewArr.find((prev) => prev.subgroup === child.subgroup)) {
          previewArr.push({ subgroup: child.subgroup, children: [] });
        }
        previewArr
          .find((prev) => prev.subgroup === child.subgroup)
          .children.push(child);
      });
    });

    let exist = false;
    if (Object.keys(softwareSelectedLPM).length > 0) {
      itemsDescriptionArr.push({
        partNumber: softwareSelectedLPM.partNumber,
        group: "Software",
        subgroup: "Software",
        type: softwareSelectedLPM.type,
        model: "",
        description: softwareSelectedLPM.description,
        performance: "",
        priceLocalUS: softwareSelectedLPM.basePrice,
        quantityNumber:
          items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
          softwareSelectedLPM.quantityNumber,
        subTotal:
          (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
            softwareSelectedLPM.quantityNumber) * softwareSelectedLPM.basePrice,
        totalPrice:
          (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
            softwareSelectedLPM.quantityNumber) * softwareSelectedLPM.basePrice,
        components: [],
      });
      previewArr.push({
        group: "Software",
        subgroup: "Software",
        children: [
          {
            partNumber: softwareSelectedLPM.partNumber,
            group: "Software",
            subgroup: "Software",
            type: softwareSelectedLPM.type,
            model: "",
            description: softwareSelectedLPM.description,
            performance: "",
            priceLocalUS: softwareSelectedLPM.basePrice,
            quantityNumber:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareSelectedLPM.quantityNumber,
            subTotal:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSelectedLPM.quantityNumber) *
              softwareSelectedLPM.basePrice,
            totalPrice:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSelectedLPM.quantityNumber) *
              softwareSelectedLPM.basePrice,
          },
        ],
      });
      exist = true;
    }

    if (Object.keys(softwareSelectedSMSA).length > 0) {
      if (exist) {
        itemsDescriptionArr.find(
          (desc) => desc.group === "Software"
        ).subTotal +=
          (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
            softwareSelectedSMSA.quantityNumber) *
          softwareSelectedSMSA.basePrice;
        itemsDescriptionArr
          .find((desc) => desc.group === "Software")
          .components.push({
            partNumber: softwareSelectedSMSA.partNumber,
            group: "Software",
            subgroup: "Software",
            type: softwareSelectedSMSA.type,
            model: "",
            description: softwareSelectedSMSA.description,
            performance: "",
            priceLocalUS: softwareSelectedSMSA.basePrice,
            quantityNumber:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareSelectedSMSA.quantityNumber,
            subTotal:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
              softwareSelectedSMSA.basePrice,
            totalPrice:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSelectedSMSA.quantityNumber) *
              softwareSelectedSMSA.basePrice,
          });
        previewArr
          .find((prev) => prev.group === "Software")
          .children.push({
            partNumber: softwareSelectedSMSA.partNumber,
            group: "Software",
            subgroup: "Software",
            type: softwareSelectedSMSA.type,
            model: "",
            description: softwareSelectedSMSA.description,
            performance: "",
            priceLocalUS: softwareSelectedSMSA.basePrice,
            quantityNumber:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareSelectedSMSA.quantityNumber,
            subTotal:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
              softwareSelectedSMSA.basePrice,
            totalPrice:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSelectedSMSA.quantityNumber) *
              softwareSelectedSMSA.basePrice,
          });
      } else {
        itemsDescriptionArr.push({
          partNumber: softwareSelectedSMSA.partNumber,
          group: "Software",
          subgroup: "Software",
          type: softwareSelectedSMSA.type,
          model: "",
          description: softwareSelectedSMSA.description,
          performance: "",
          priceLocalUS: softwareSelectedSMSA.basePrice,
          quantityNumber:
            items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
            softwareSelectedSMSA.quantityNumber,
          subTotal:
            items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
            softwareSelectedSMSA.basePrice,
          totalPrice:
            (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareSelectedSMSA.quantityNumber) *
            softwareSelectedSMSA.basePrice,
          components: [],
        });
        previewArr.push({
          group: "Software",
          subgroup: "Software",
          children: [
            {
              partNumber: softwareSelectedSMSA.partNumber,
              group: "Software",
              subgroup: "Software",
              type: softwareSelectedSMSA.type,
              model: "",
              description: softwareSelectedSMSA.description,
              performance: "",
              priceLocalUS: softwareSelectedSMSA.basePrice,
              quantityNumber:
                items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSelectedSMSA.quantityNumber,
              subTotal:
                items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                softwareSelectedSMSA.basePrice,
              totalPrice:
                (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareSelectedSMSA.quantityNumber) *
                softwareSelectedSMSA.basePrice,
            },
          ],
        });
      }
    }

    setItemsDescription(itemsDescriptionArr);
    setItemsPreview(previewArr);

    setStep(2);
  };

  const handleGetListPrintersDataResponse = (products) => {
    if (products && products.error) {
      toast(
        "Ha ocurrido un error, no existe una base de datos de configuración activa, Active una base de datos de configuración e intentelo de nuevo, por favor.",
        { type: "error", autoClose: 10000 }
      );
      return;
    }
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      let printersAux = Object.keys(products).map((itemsAux) => {
        const items = products[itemsAux];
        let printerParent = items.find((printer) => printer.impresora === true);
        let components = items.filter((printer) => printer.impresora === false);

        return {
          partNumber: printerParent.numeroParte,
          id: printerParent.id,
          group: printerParent.grupo,
          subgroup: printerParent.subgrupo,
          type: printerParent.tipo,
          model: printerParent.modelo,
          description: printerParent.descripcion,
          performance: "",
          priceLocalUS: printerParent.precioReferencialUnitario,
          volumen: printerParent.volumen,
          components: components.map((component) => {
            return {
              partNumber: component.numeroParte,
              group: component.grupo,
              subgroup: component.subgrupo,
              type: component.tipo,
              model: component.modelo,
              description: component.descripcion,
              performance: component.rendimiento,
              priceLocalUS: component.precioReferencialUnitario,
              partNumberParent: component.numeroPartePadre,
              volumen: component.volumen,
            };
          }),
        };
      });

      setPrinters(printersAux);
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getPrinters = () => {
    setItemSelected({});
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/v1/configurador/suministros/search/${searchPrinter}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetListPrintersDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <div className="step-progress-container">
                    <div
                      className={`step ${step >= 0 ? "active" : ""} text-left`}
                    >
                      Impresoras
                    </div>
                    <div
                      className={`step ${step >= 1 ? "active" : ""} m-negative`}
                    >
                      Software
                    </div>
                    <div
                      className={`step  ${
                        step >= 2 ? "active" : ""
                      } text-right`}
                    >
                      Previsualización
                    </div>
                  </div>
                  <div className="step-progress-container step-container">
                    <div className={`step ${step >= 0 ? "active" : ""}`}>
                      <span className="step-number  m-0 ">1</span>
                    </div>
                    <div className={`step ${step >= 1 ? "active" : ""}`}>
                      <span className="step-number">2</span>
                    </div>
                    <div className={`step  ${step >= 2 ? "active" : ""}`}>
                      <span className="step-number m-step-3">3</span>
                    </div>
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${step * 50}%` }}
                      ></div>
                    </div>
                  </div>
                  <br />
                  <br />

                  {step === 0 && (
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="6">
                          <h4 className="card-title">DATOS CONFIGURACIÓN</h4>
                        </Col>
                        <Col md="6">
                          <div className="text-right">
                            <Button
                              type="button"
                              className="btn btn-secondary-lexmark"
                              onClick={handleShow}
                            >
                              Agregar Configuración
                            </Button>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3 d-flex flex-column">
                            <Label
                              className="form-label"
                              htmlFor="validationCustom05"
                            >
                              Entidad
                            </Label>

                            <AvField
                              name="entidad"
                              type="select"
                              errorMessage="Ingrese la entidad"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={nameEntity}
                              onChange={(e) => {
                                setNameEntity(e.target.value);
                                setNameOpportunity(null)
                                getOpportunities(e.target.value);
                              }}
                            >
                              <option
                                value={""}
                                selected="true"
                                disabled="disabled"
                              >
                                Seleccione una entidad
                              </option>

                              {entities.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.entidad?.toUpperCase()}
                                >
                                  {item.entidad?.toUpperCase()}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3 d-flex flex-column">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom05"
                              >
                                Oportunidad
                              </Label>

                              <AvField
                                name="opportunity"
                                type="select"
                                className="form-control"
                                value={nameOpportunity}
                                onChange={(e) =>
                                  setNameOpportunity(e.target.value)
                                }
                              >
                                <option
                                  value={""}
                                  selected="true"
                                  disabled="disabled"
                                >
                                  Seleccione una oportunidad
                                </option>

                                <option value={""}>Sin oportunidad</option>
                                {opportunities.map((item, index) => (
                                  <option key={index} value={item.codigo}>
                                    {item.codigo} - {item.descripcion}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                        </Col>

                        <Col md="12">
                          <hr />
                          <br />
                          <div className="table-responsive">
                            <table className="custom-table">
                              <thead>
                                <tr>
                                  {columnsParent.map((column, index) => (
                                    <th className="text-center" key={index}>
                                      {column.text}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((item, indexParent) => (
                                  <React.Fragment key={item.index}>
                                    <tr>
                                      {columnsParent.map((column, index) => {
                                        if (column.dataField === "indexGroup")
                                          return (
                                            <td
                                              className="text-center parent-row color-black"
                                              key={index}
                                              rowspan={
                                                item.components.length + 2
                                              }
                                            >
                                              {indexParent + 1}
                                            </td>
                                          );

                                        return (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumber
                                              ? (+item[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : column.isNumberWithouthDecimal &&
                                                item[column.dataField]
                                              ? (+item[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                    maximumFractionDigits: 0,
                                                  })
                                                  .replace(/\$/g, "")
                                              : item[column.dataField]}

                                            {column.dataField ===
                                              "quantity" && (
                                              <Input
                                                type="number"
                                                value={item.quantityNumber}
                                                onChange={(e) =>
                                                  handleQuantityParentChange(
                                                    indexParent,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            )}

                                            {column.dataField === "actions" && (
                                              <div className="text-center">
                                                <Button
                                                  size="sm"
                                                  type="button"
                                                  className="danger"
                                                  onClick={() =>
                                                    deleteParent(indexParent)
                                                  }
                                                >
                                                  {" "}
                                                  <i class="ri-delete-bin-line"></i>{" "}
                                                </Button>
                                              </div>
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    {item.components.map(
                                      (child, indexChild) => (
                                        <tr
                                          key={child.index}
                                          className="child-row"
                                        >
                                          {columnsParent
                                            .filter(
                                              (column) =>
                                                column.dataField !==
                                                "indexGroup"
                                            )
                                            .map((column, index) => (
                                              <td
                                                key={index}
                                                className={column.className}
                                              >
                                                {column.dataField ===
                                                  "quantity" && (
                                                  <Input
                                                    type="number"
                                                    value={child.quantityNumber}
                                                    onChange={(e) =>
                                                      handleQuantityChange(
                                                        indexParent,
                                                        indexChild,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                                {column.isNumber
                                                  ? (+child[column.dataField])
                                                      .toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD",
                                                      })
                                                      .replace(/\$/g, "$ ")
                                                  : column.isNumberWithouthDecimal &&
                                                    child[column.dataField]
                                                  ? (+child[column.dataField])
                                                      .toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD",
                                                        maximumFractionDigits: 0,
                                                      })
                                                      .replace(/\$/g, "")
                                                  : child[column.dataField]}
                                                {column.dataField ===
                                                  "actions" && (
                                                  <div className="text-center">
                                                    <Button
                                                      size="sm"
                                                      type="button"
                                                      className="danger"
                                                      onClick={() =>
                                                        deleteChildren(
                                                          indexParent,
                                                          indexChild
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <i class="ri-delete-bin-line"></i>{" "}
                                                    </Button>
                                                  </div>
                                                )}
                                              </td>
                                            ))}
                                        </tr>
                                      )
                                    )}
                                    <tr key={`subtotal-indexParent`}>
                                      {columnsParent
                                        .filter(
                                          (column) =>
                                            column.dataField !== "indexGroup"
                                        )
                                        .map((column, index) => (
                                          <td
                                            key={index}
                                            className={`parent-row color-black ${column.className}`}
                                          >
                                            {index === 0 && "Subtotal"}
                                            {index ===
                                              columnsParent.length - 3 &&
                                              (+item.subTotal)
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(/\$/g, "$ ")}
                                          </td>
                                        ))}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                <tr>
                                  <td
                                    colSpan={columnsParent.length - 2}
                                    className="text-center"
                                  >
                                    Total
                                  </td>

                                  <td className="text-right">
                                    {(+items.reduce(
                                      (acc, item) => acc + +item.subTotal,
                                      0
                                    ))
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")}
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <br />

                      <br />

                      <div className="text-right">
                        <Button
                          className="btn btn-primary-lexmark box-shadow-none"
                          type="button"
                          disabled={loading || !nameEntity}
                          onClick={handleStep2}
                        >
                          Siguiente
                        </Button>
                      </div>
                    </AvForm>
                  )}

                  {step === 1 && (
                    <Row>
                      <Col md="12">
                        <h4 className="card-title">DATOS SOFTWARE</h4>
                        <hr />
                      </Col>
                      <Col md="6">
                        <select
                          className="form-control"
                          name="item"
                          id="configurator-add-item"
                          value={softwareSelectedLPM?.partNumber}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setSoftwareSelectedLPM({});
                              return;
                            }
                            setSoftwareSelectedLPM({
                              ...softwareList.find(
                                (data) => data.partNumber === e.target.value
                              ),
                              quantityNumber: 1,
                            });
                          }}
                        >
                          <option value={""} selected="true">
                            Seleccione un software LPM
                          </option>
                          {softwareList
                            .filter(
                              (software) =>
                                software.type === "Por número de impresoras"
                            )
                            .map((data) => (
                              <option value={data.partNumber}>
                                {data.description}
                              </option>
                            ))}
                        </select>
                      </Col>

                      <Col md="6">
                        <select
                          className="form-control"
                          name="item"
                          id="configurator-add-item"
                          value={softwareSelectedSMSA?.partNumber || ""}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setSoftwareSelectedSMSA({});
                              return;
                            }
                            setSoftwareSelectedSMSA({
                              ...softwareList.find(
                                (data) => data.partNumber === e.target.value
                              ),
                              quantityNumber: 1,
                            });
                          }}
                        >
                          <option value={""} selected="true">
                            Seleccione un software SMSA
                          </option>
                          {softwareList
                            .filter((software) => software.type === "Por año")
                            .map((data) => (
                              <option value={data.partNumber}>
                                {data.description}
                              </option>
                            ))}
                        </select>
                      </Col>
                      <Col md="12">
                        <br />
                        <br />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsSoftware.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {(Object.keys(softwareSelectedLPM).length > 0 ||
                              Object.keys(softwareSelectedSMSA).length > 0) && (
                              <tbody>
                                {Object.keys(softwareSelectedLPM).length >
                                  0 && (
                                  <tr>
                                    {columnsSoftware.map((column, index) => {
                                      return (
                                        <td
                                          key={index}
                                          className={column.className}
                                        >
                                          {column.dataField === "quantity" && (
                                            <Input
                                              type="number"
                                              value={
                                                items.reduce(
                                                  (acc, item) =>
                                                    acc + +item.quantityNumber,
                                                  0
                                                ) ||
                                                softwareSelectedLPM.quantityNumber
                                              }
                                              disabled={
                                                items.filter(
                                                  (printer) =>
                                                    printer.quantityNumber > 0
                                                ).length > 0
                                              }
                                              onChange={(e) =>
                                                setSoftwareSelectedLPM({
                                                  ...softwareSelectedLPM,
                                                  quantityNumber:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          )}

                                          {column.dataField === "totalPrice" &&
                                            (
                                              +softwareSelectedLPM.basePrice *
                                              (items.reduce(
                                                (acc, item) =>
                                                  acc + +item.quantityNumber,
                                                0
                                              ) ||
                                                softwareSelectedLPM.quantityNumber)
                                            )
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}

                                          {column.isNumber &&
                                          softwareSelectedLPM[column.dataField]
                                            ? (+softwareSelectedLPM[
                                                column.dataField
                                              ])
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(/\$/g, "$ ")
                                            : softwareSelectedLPM[
                                                column.dataField
                                              ]}

                                          {column.dataField === "actions" && (
                                            <div className="text-center">
                                              <Button
                                                size="sm"
                                                type="button"
                                                className="danger"
                                                onClick={() =>
                                                  setSoftwareSelectedLPM({})
                                                }
                                              >
                                                <i class="ri-delete-bin-line"></i>{" "}
                                              </Button>
                                            </div>
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                )}

                                {Object.keys(softwareSelectedSMSA).length >
                                  0 && (
                                  <tr>
                                    {columnsSoftware.map((column, index) => {
                                      return (
                                        <td
                                          key={index}
                                          className={column.className}
                                        >
                                          {column.dataField === "quantity" && (
                                            <Input
                                              type="number"
                                              value={
                                                items.reduce(
                                                  (acc, item) =>
                                                    acc + +item.quantityNumber,
                                                  0
                                                ) ||
                                                softwareSelectedSMSA.quantityNumber
                                              }
                                              disabled={
                                                items.filter(
                                                  (printer) =>
                                                    printer.quantityNumber > 0
                                                ).length > 0
                                              }
                                              onChange={(e) =>
                                                setSoftwareSelectedSMSA({
                                                  ...softwareSelectedSMSA,
                                                  quantityNumber:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          )}

                                          {column.dataField === "totalPrice" &&
                                            (
                                              +softwareSelectedSMSA.basePrice *
                                              (items.reduce(
                                                (acc, item) =>
                                                  acc + +item.quantityNumber,
                                                0
                                              ) ||
                                                softwareSelectedSMSA.quantityNumber)
                                            )
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}

                                          {column.isNumber &&
                                          softwareSelectedSMSA[column.dataField]
                                            ? (+softwareSelectedSMSA[
                                                column.dataField
                                              ])
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(/\$/g, "$ ")
                                            : softwareSelectedSMSA[
                                                column.dataField
                                              ]}

                                          {column.dataField === "actions" && (
                                            <div className="text-center">
                                              <Button
                                                size="sm"
                                                type="button"
                                                className="danger"
                                                onClick={() =>
                                                  setSoftwareSelectedSMSA({})
                                                }
                                              >
                                                <i class="ri-delete-bin-line"></i>{" "}
                                              </Button>
                                            </div>
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </Col>
                      <Col md="12">
                        <br />
                        <br />
                        <div className="text-right">
                          <Button
                            className="btn btn-secondary-lexmark mr-3"
                            type="submit"
                            onClick={() => setStep(0)}
                          >
                            Volver
                          </Button>
                          <Button
                            className="btn btn-primary-lexmark box-shadow-none"
                            type="submit"
                            disabled={loading}
                            onClick={handlePreview}
                          >
                            Siguiente
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {step === 2 && (
                    <Row>
                      <Col md="12">
                        <h4 className="card-title text-center">
                          CONFIGURACIÓN LEXMARK
                        </h4>{" "}
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">Fecha: {getDateToday()}</p>
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          Nombre del reseller:{" "}
                          {profile ? profile.razonSocial : ""}
                        </p>
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          Datos Cliente: {nameEntity}{" "}
                        </p>{" "}
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          DESCRIPCIÓN DE LOS PRODUCTOS{" "}
                        </p>
                      </Col>

                      <Col md="12">
                        <hr />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsPreviewParent.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {itemsDescription.map((item, indexParent) => (
                                <React.Fragment key={item.index}>
                                  <tr>
                                    {columnsPreviewParent.map(
                                      (column, index) => {
                                        if (column.dataField === "indexGroup")
                                          return (
                                            <td
                                              className="text-center parent-row color-black"
                                              key={index}
                                              rowspan={
                                                item.components.length + 2
                                              }
                                            >
                                              {indexParent + 1}
                                            </td>
                                          );

                                        return (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumber
                                              ? (+item[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : item[column.dataField]}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                  {item.components.map((child, indexChild) => (
                                    <tr key={child.index} className="child-row">
                                      {columnsPreviewParent
                                        .filter(
                                          (column) =>
                                            column.dataField !== "indexGroup"
                                        )
                                        .map((column, index) => (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumber
                                              ? (+child[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : child[column.dataField]}
                                          </td>
                                        ))}
                                    </tr>
                                  ))}
                                  <tr key={`subtotal-indexParent`}>
                                    {columnsPreviewParent
                                      .filter(
                                        (column) =>
                                          column.dataField !== "indexGroup"
                                      )
                                      .map((column, index) => (
                                        <td
                                          key={index}
                                          className={`parent-row color-black ${column.className}`}
                                        >
                                          {index === 0 && "Subtotal"}

                                          {index ===
                                            columnsPreviewParent.length - 2 &&
                                            (+item.subTotal)
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}
                                        </td>
                                      ))}
                                  </tr>
                                </React.Fragment>
                              ))}
                              <tr>
                                <td
                                  colSpan={columnsPreviewParent.length - 1}
                                  className="text-center"
                                >
                                  Total
                                </td>

                                <td className="text-right">
                                  {(+itemsDescription.reduce(
                                    (acc, item) => acc + +item.subTotal,
                                    0
                                  ))
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(/\$/g, "$ ")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>

                      <Col md="12">
                        <br />
                        <br />
                        <p className="card-title">RESUMEN</p>
                      </Col>
                      <Col md="12">
                        <hr />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsPreview.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {itemsPreview.map((item, indexParent) => (
                                <React.Fragment key={item.index}>
                                  <tr key={indexParent}>
                                    <td
                                      className="text-center parent-row color-black"
                                      key={indexParent}
                                      rowspan={item.children.length + 2}
                                    >
                                      {indexParent + 1}
                                    </td>
                                    <td
                                      className="text-center bg-white color-black"
                                      key={indexParent}
                                      rowspan={item.children.length + 1}
                                    >
                                      {item.subgroup}
                                    </td>
                                  </tr>
                                  {item.children.map(
                                    (itemChild, indexChild) => (
                                      <tr key={indexParent}>
                                        {columnsPreview
                                          .filter(
                                            (col) =>
                                              col.dataField !== "indexGroup" &&
                                              col.dataField !== "subgroup"
                                          )
                                          .map((column, index) => {
                                            return (
                                              <td
                                                key={index}
                                                className={column.className}
                                              >
                                                {column.isNumber
                                                  ? (+itemChild[
                                                      column.dataField
                                                    ])
                                                      .toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD",
                                                      })
                                                      .replace(/\$/g, "$ ")
                                                  : itemChild[column.dataField]}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                    )
                                  )}
                                  <tr key={`subtotal-indexParent`}>
                                    {columnsPreview
                                      .filter(
                                        (column) =>
                                          column.dataField !== "indexGroup"
                                      )
                                      .map((column, index) => (
                                        <td
                                          key={index}
                                          className={`parent-row color-black ${column.className}`}
                                        >
                                          {index === 0 && "Subtotal"}

                                          {index === columnsParent.length - 9 &&
                                            +item.children.reduce(
                                              (acc2, child) =>
                                                acc2 + +child.quantityNumber,
                                              0
                                            )}
                                          {index === columnsParent.length - 7 &&
                                            (+item.children.reduce(
                                              (acc2, child) =>
                                                acc2 + +child.totalPrice,
                                              0
                                            ))
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}
                                        </td>
                                      ))}
                                  </tr>
                                </React.Fragment>
                              ))}
                              <tr>
                                <td colSpan={6} className="text-center">
                                  Total
                                </td>

                                <td className="text-right">
                                  {
                                    +itemsPreview.reduce(
                                      (acc, item) =>
                                        acc +
                                        item.children.reduce(
                                          (acc2, child) =>
                                            acc2 + +child.quantityNumber,
                                          0
                                        ),
                                      0
                                    )
                                  }
                                </td>
                                <td></td>
                                <td className="text-right">
                                  {(+itemsPreview.reduce(
                                    (acc, item) =>
                                      acc +
                                      item.children.reduce(
                                        (acc2, child) =>
                                          acc2 + +child.totalPrice,
                                        0
                                      ),
                                    0
                                  ))
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(/\$/g, "$ ")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md="12">
                        <br />
                        <br />
                        <div className="text-right">
                          <Button
                            className="btn btn-secondary-lexmark mr-3"
                            type="submit"
                            disabled={loading}
                            onClick={() => setStep(1)}
                          >
                            Volver
                          </Button>
                          <Button
                            className="btn btn-primary-lexmark box-shadow-none"
                            type="submit"
                            disabled={loading || itemsPreview.length === 0}
                            onClick={handleSubmit}
                          >
                            Guardar
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente la Configuración{" "}
                <span style={{ fontWeight: "bold" }}> {form.title} </span>.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheConfiguratorIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>

      <ModalCustom
        show={show}
        handleClose={handleClose}
        actionBtnLabel={"AGREGAR"}
        title="Agregar Configuración"
        customClass={"btn-primary-lexmark"}
        disabledSave={
          !itemSelected ||
          Object.keys(itemSelected).length === 0 ||
          (Object.keys(itemSelected).length > 0 &&
            itemSelected.components.filter((item) => item.selected).length ===
              0) ||
          (itemSelected && !itemSelected.monthVolume)
        }
        onClickEvent={() => {
          const updatedItems = [...items];
          const itemToAdd = { ...itemSelected };

          itemToAdd.components = itemToAdd.components
            .filter((child) => child.selected)
            .map((child, index) => ({
              ...child,
              quantityNumber: 1,
              totalPrice: +(child.quantityNumber || 1) * +child.priceLocalUS,
            }));

          if (itemToAdd.monthVolume) {
            itemToAdd.components.forEach((child) => {
              if (child.performance) {
                let monthVolume = +itemToAdd.monthVolume;
                let monthVolumeColor = +itemToAdd.monthVolumeColor;
                let totalVolumenBlack = monthVolume * itemToAdd.quantityNumber;
                let totalVolumeColor =
                  monthVolumeColor * itemToAdd.quantityNumber;
                let totalVolume = totalVolumeColor + totalVolumenBlack;
                let performance = +child.performance;
                if (child.volumen === "black") {
                  child.quantityNumber = Math.ceil(
                    totalVolumenBlack / performance
                  );
                } else if (child.volumen === "color") {
                  child.quantityNumber = Math.ceil(
                    totalVolumeColor / performance
                  );
                } else if (child.volumen === "total") {
                  child.quantityNumber = Math.ceil(totalVolume / performance);
                }
                child.totalPrice =
                  +(child.quantityNumber || 1) * +child.priceLocalUS;
              }
            });
          }

          itemToAdd.totalPrice = itemToAdd.priceLocalUS;

          itemToAdd.subTotal = (
            itemToAdd.components.reduce(
              (acc, item) => acc + +item.totalPrice,
              0
            ) + +itemToAdd.totalPrice
          ).toFixed(2);

          updatedItems.push(itemToAdd);

          setItems(updatedItems);
          handleClose();
        }}
      >
        <div className="modal-content-deny-opportunity">
          <div>
            <Label className="form-label" htmlFor="configurator-add-item">
              Buscar impresora
            </Label>
            <div className="mb-3 d-flex">
              <Col md={12} style={{ width: "100%" }}>
                <div className="search-printer">
                  <Input
                    type="text"
                    value={searchPrinter}
                    onChange={(e) => setSearchPrinter(e.target.value)}
                    placeholder="29S0050,MS431dn,42/40 ppm, 256MB, 1 GHz, 2-line APA display, DC 80K"
                  />

                  <Button
                    className="btn btn-primary-lexmark box-shadow-none"
                    type="button"
                    onClick={getPrinters}
                    disabled={searchPrinter.length === 0}
                  >
                    <i className="fa fa-search"></i>
                  </Button>
                </div>
                <hr />
                {Object.keys(itemSelected).length === 0 &&
                  printers.length > 0 && (
                    <div className="printers-container">
                      {printers.map((data, index) => (
                        <div
                          className="printer-item printer-item-list"
                          key={index}
                          onClick={() => {
                            setItemSelected({
                              ...data,
                              monthVolumeColor: null,
                              monthVolume: null,
                            });
                          }}
                        >
                          <p>
                            <b>Número de Parte:</b> {data.partNumber} <br />
                            <b>Modelo:</b> {data.model}
                          </p>

                          <p>
                            <b>Descripción:</b> {data.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                {Object.keys(itemSelected).length > 0 && (
                  <div className="printer-item">
                    <p>
                      <b>Número de Parte:</b> {itemSelected.partNumber} <br />
                      <b>Modelo:</b> {itemSelected.model}
                    </p>
                    <p>
                      <b>Descripción:</b> {itemSelected.description}
                    </p>
                    <div
                      className="remove-printer"
                      onClick={() => setItemSelected({})}
                    >
                      {" "}
                      X{" "}
                    </div>
                  </div>
                )}
              </Col>
            </div>
          </div>

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Cantidad de impresora(s)
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    value={itemSelected.quantityNumber}
                    onChange={(e) => {
                      if (e.target.value) {
                        setItemSelected({
                          ...itemSelected,
                          quantityNumber: +e.target.value,
                        });
                      } else {
                        setItemSelected({
                          ...itemSelected,
                          quantityNumber: null,
                        });
                      }
                    }}
                  />
                </Col>
              </div>
            </div>
          )}

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Ingresar volumen B/N mensual por impresora
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    value={itemSelected.monthVolume}
                    onChange={(e) =>
                      setItemSelected({
                        ...itemSelected,
                        monthVolume: e.target.value,
                      })
                    }
                  />
                </Col>
              </div>
            </div>
          )}

          {!!itemSelected &&
            Object.keys(itemSelected).length > 0 &&
            itemSelected.type.toLowerCase().includes("color") && (
              <div>
                <Label className="form-label" htmlFor="configurator-add-item">
                  Ingresar volumen de color mensual por impresora
                </Label>
                <div className="mb-3 d-flex">
                  <Col md={12} style={{ width: "100%" }}>
                    <Input
                      type="number"
                      value={itemSelected.monthVolumeColor}
                      onChange={(e) =>
                        setItemSelected({
                          ...itemSelected,
                          monthVolumeColor: e.target.value,
                        })
                      }
                    />
                  </Col>
                </div>
              </div>
            )}
          <div>
            <div className="table-responsive">
              {!!itemSelected && Object.keys(itemSelected).length > 0 && (
                <table className="custom-table">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th className="text-center" key={index}>
                          {column.text}
                        </th>
                      ))}
                      <th> Agregar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemSelected?.components?.map((item) => (
                      <React.Fragment key={item.index}>
                        <tr className="child-row">
                          {columns.map((column, index) => (
                            <td key={index}>{item[column.dataField]}</td>
                          ))}
                          <td className="text-center">
                            <Input
                              type="checkbox"
                              checked={item.selected || false}
                              className="form-check-input"
                              onChange={() =>
                                handleSelectSupplies(item.partNumber)
                              }
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </ModalCustom>
    </React.Fragment>
  );
};

export default ConfiguratorRegister;
