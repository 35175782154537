import React, { Component, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';

// users
import avatar2 from '../../../assets/images/users/robot.png';
import useHttp from '../../../hook/use-http';
import { useHistory } from "react-router-dom";
import { getProfile } from '../../../store/actions';


const ProfileMenu = ({ t }) => {

    const [menu, setMenu] = useState(false);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // this variable is used to redirect the user to another page
    let history = useHistory();

    // this variable will be used to execute the dispatch function
    const dispatch = useDispatch();
    // this function will be used to get the user profile
    let profile = useSelector(state => state.profileReducer.profile);

    useEffect(() => {
        dispatch(getProfile());
    }, [])

    const toggleFunction = () => {
        setMenu(!menu);
    }

    let username = "Admin";
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const uNm = obj.email.split("@")[0];
        username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    }

    const handleLogoutDataResponse = (response) => {
        console.log(response);
        console.log('entra a logout');

        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('email')
        history.push('/login');
        //window.location.href = '/login';
    }

    const handleLogout = () => {
        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/user/logout`,
                method: 'POST',
                headers: myHeaders,
            }, handleLogoutDataResponse)

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggleFunction} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                    <img className="rounded-circle header-profile-user me-1" src={avatar2} alt="Header Avatar" />
                    <span className="d-none d-xl-inline-block ms-1 text-transform" style={{ color: '#74788d' }}>{profile ? profile.razonSocial : ''}</span>
                    <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block" style={{ color: '#74788d' }}></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {/** 
                    <DropdownItem href="#"><i className="ri-user-line align-middle me-1"></i> {t('Perfil')}</DropdownItem>
                    */}
                    <DropdownItem onClick={() => history.push("/configuration")}  ><i className="ri-settings-2-line align-middle me-1"></i> {t('Configuración')}</DropdownItem>
                    {/* 
                        <DropdownItem href="#"><i className="ri-wallet-2-line align-middle me-1"></i> {this.props.t('My Wallet')}</DropdownItem>
                        <DropdownItem className="d-block" href="#"><span className="badge badge-success float-end mt-1">11</span><i className="ri-settings-2-line align-middle me-1"></i> {this.props.t('Settings')}</DropdownItem>
                        <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle me-1"></i> {this.props.t('Lock screen')}</DropdownItem>
                        */}
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" onClick={handleLogout}><i className="ri-shut-down-line align-middle me-1 text-danger"></i> {t('Cerrar Sesión')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );

}

export default withNamespaces()(ProfileMenu);
