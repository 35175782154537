import { AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { BsCursor, BsFillArrowDownCircleFill, BsMinecartLoaded, BsPencilSquare, BsPhone, BsPlusCircle, BsRecord, BsRecycle } from 'react-icons/bs';
import { Row } from 'reactstrap';
import { saveAs } from 'file-saver'
import useHttp from "../../hook/use-http";


// this variable will represent different supplies
const supplies = [
    {
        id: 1,
        name: 'DISTRIBUIDOR AUTORIZADO',
        title: 'DISTRIBUIDOR_AUTORIZADO',
        icon: <BsMinecartLoaded />,
        code: "CER_SUM_001"
    },
    {
        id: 2,
        name: 'GARANTÍA TÉCNICA',
        title: 'GARANTÍA_TÉCNICA',
        icon: <BsPhone />,
        code: "CER_SUM_002"
    },
    {
        id: 3,
        name: 'COMUNICADO DE ORIGINALIDAD',
        title: 'COMUNICADO_DE_ORIGINALIDAD',
        icon: <BsPencilSquare />,
        code: "CER_SUM_003"

    },
    {
        id: 4,
        name: 'CERTIFICADO AMBIENTAL',
        title: 'CERTIFICADO_AMBIENTAL',
        icon: <BsRecycle />,
        code: "CER_SUM_004"
    },
    {
        id: 5,
        name: 'CERTIFICADO ISO',
        title: 'CERTIFICADO_ISO',
        icon: <BsRecord />,
        code: "CER_SUM_005"
    }
]

const Supply = ({ handleChangeListCertificatedSelected, certificationSelected, estado, match, codeRequest }) => {

    const [suppliesSelected, setSuppliesSelected] = useState([]);
    const [isParams, setIsParams] = useState(false);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    const [statusRequest, setStatusRequest] = useState(estado);

    useEffect(() => {
        handleChangeListCertificatedSelected(suppliesSelected)
    }, [suppliesSelected])

    useEffect(() => {
        if (certificationSelected.length > 0 && !isParams) {
            console.log(certificationSelected)

            certificationSelected.forEach(item => {
                // item represent the code of the supply

                // here i get the element of the supplies array that has the same code of the certificationSelected
                const supply = supplies.find(x => {
                    return x.code === item
                })

                setSuppliesSelected((prov) => {
                    return [...prov, supply]
                })

                // now we need to change the color of the div component
                let container = document.getElementById(supply.name);
                container.style.backgroundColor = '#008945'
                container.style.color = 'white'

                let title = document.getElementById(supply.title)
                title.style.color = 'white'

                console.log(supply)
            })
            setIsParams(true);
        }
    })


    // this function will be used to add or not supplies into list
    const addSuppliesToList = (selected) => {

        const supply = suppliesSelected.find(item => {
            return item.name === selected.name
        })

        // add to supply into list
        if (!supply) {
            // add supply into list
            setSuppliesSelected((prov) => {
                return [
                    ...prov,
                    selected
                ]
            })
            // now we need to change the color of the div component
            let container = document.getElementById(selected.name);
            container.style.backgroundColor = '#008945'
            container.style.color = 'white'

            let title = document.getElementById(selected.title)
            title.style.color = 'white'


        } else { // delete supply into list

            setSuppliesSelected((prov) => {
                const filter = prov.filter(item => {
                    return item.name !== supply.name
                })
                return filter
            })

            let container = document.getElementById(selected.name);
            container.style.backgroundColor = 'white'
            container.style.color = '#433A40'

            let title = document.getElementById(selected.title)
            title.style.color = '#433A40'
        }

    }

    const renderedSupplies = Object.values(supplies).map(item => {
        return (
            <div
                key={item.id}
                id={item.name}
                className="form-check" style={{
                    border: '1px solid #CDCDCD',
                    marginTop: 20
                }}
                onClick={() => addSuppliesToList(item)}
            >
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                        <i style={{ fontSize: 25, marginRight: 10 }}>
                            {item.icon}
                        </i>
                        <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                            {item.name}
                        </h6>
                    </div>
                    {/*
                    <div style={{ marginRight: 30, display: 'flex' }}>
                        <input style={{ margin: 'auto' }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                     */}
                </div>
            </div>
        )
    })

    const handleRequestDownloadDataResponse = async (response) => {
        const result = await response.blob();
        const blob = new Blob([result], { type: 'application/pdf' })
        saveAs(blob, "certificados.pdf")
    }

    const handleRequestDownload = () => {

        try {
            try {
                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                myHeaders.append("Accept", "application/pdf");
                myHeaders.append("responseType", "blob");

                request({
                    url: '/auth/certificado/download/' + codeRequest,
                    method: 'GET',
                    headers: myHeaders,
                }, handleRequestDownloadDataResponse)

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    CERTIFICADO
                </h4>
                {
                    estado === 'APROBADO' && (
                        <button
                            className="btn btn-info"
                            onClick={handleRequestDownload}
                            style={{ backgroundColor: '#3af23a', color: 'white' }}
                        >
                            <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillArrowDownCircleFill />
                            </div>
                        </button>
                    )
                }
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </>
    )

}

export default Supply;