import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as XLSX from "xlsx";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import Spreadsheet from "react-spreadsheet";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

const ConfiguratorItemsDetails = ({ match, history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const printers = ["SFP", "MFP", "MPF A3", "MFP A3"];
  const columnLabels = [
    "NUMERO DE PARTE",
    "GRUPO",
    "TIPO",
    "MODELO",
    "DESCRIPCION",
    "RENDIMIENTO",
    "PRECIO LOCAL US$ CANAL",
    "SUBGRUPO"
  ];

  // get the data with redux dispatch
  // this will be used to control de component modal

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  // this function will be process the result of the method getListNote
  const handleGetItemsDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        console.log(response);
        const jsonData = [];
        Object.keys(response).forEach((key) => {
          response[key].forEach((printer) => {
            jsonData.push({
              ...printer,
              numero_de_parte: printer.numeroParte,
              numero_de_parte_padre: printer.numeroPartePadre,
              precio_local_us_canal: printer.precioReferencialUnitario,
            });
          });
        });

        handleDataFormatter(jsonData);
        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  // this function will be used to get profile of channel by ruc
  const getItems = () => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        const { id } = match.params;

        request(
          {
            url: `/auth/v1/configurador/suministros/list-by-parent/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          handleGetItemsDataResponse
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // i have different question here
    getItems();
    // these function will be used to get all documents of the channel
    //getDocuments();
  }, []);

  const handleDataFormatter = (jsonData) => {
    let auxData = [];
    let numero_de_parte_padre = null;
    jsonData.forEach((item) => {
      item.numero_de_parte = "" + item.numero_de_parte;
      if (printers.includes(item.grupo)) {
        item.numero_de_parte_padre = null;
        numero_de_parte_padre = item.numero_de_parte;
      } else {
        item.numero_de_parte_padre = numero_de_parte_padre;
        if (item.tipo === "Mono Laser") {
          if (item.rendimiento && item.grupo.substring(0, 8) === "Supplies") {
            item.volumen = "black";
          } else {
            item.volumen = "total";
          }
        } else if (item.tipo === "Color Laser") {
          const lastTwoChart = item.numero_de_parte.slice(-2);
          const color = ["C0", "M0", "Y0"];
          const black = ["K0"];
          if (
            item.rendimiento &&
            color.includes(lastTwoChart) &&
            item.grupo.substring(0, 8) === "Supplies"
          ) {
            item.volumen = "color";
          } else if (
            item.rendimiento &&
            black.includes(lastTwoChart) &&
            item.grupo.substring(0, 8) === "Supplies"
          ) {
            item.volumen = "black";
          } else {
            item.volumen = "total";
          }
        }
      }
    });
    jsonData.forEach((item) => {
      let auxRow = [
        { value: item.numero_de_parte, readOnly: true },
        { value: item.grupo, readOnly: true },
        { value: item.tipo, readOnly: true },
        { value: item.modelo, readOnly: true },
        { value: item.descripcion, readOnly: true },
        { value: item.rendimiento, readOnly: true },
        { value: item.precio_local_us_canal, readOnly: true },
        { value: item.subgrupo, readOnly: true },
      ];

      auxData.push(auxRow);
    });

    let dataOriginalAux = [];
    jsonData.forEach((item) => {
      let auxRow = {
        numeroParte: item.numero_de_parte || "",
        grupo: item.grupo || "",
        tipo: item.tipo || "",
        modelo: item.modelo || "",
        descripcion: item.descripcion || "",
        rendimiento: item.rendimiento || "",
        impresora: item.numero_de_parte_padre ? "false" : "true",
        precioReferencialUnitario: "" + item.precio_local_us_canal || "",
        numeroPartePadre: item.numero_de_parte_padre || "",
        volumen: item.volumen || "",
        subgrupo: item.subgrupo || "",
      };
      dataOriginalAux.push(auxRow);
    });
    setDataOriginal(dataOriginalAux);
    setData(auxData);
  };

  const downloadExcel = () => {
    let dataToExport = [];
    dataOriginal.forEach((item) => {
      dataToExport.push({
        "Numero de parte": item.numeroParte,
        Grupo: item.grupo,
        Tipo: item.tipo,
        Modelo: item.modelo,
        Descripcion: item.descripcion,
        Rendimiento: item.rendimiento,
        "Precio Local US$ Canal": item.precioReferencialUnitario,
        "Subgrupo": item.subgrupo
      });
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataToExport);

    // Calculate the maximum length of each column
    const columnWidths = dataToExport.reduce((widths, row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const valueLength = String(row[key]).length;
        if (!widths[colIndex] || valueLength > widths[colIndex]) {
          widths[colIndex] = valueLength;
        }
      });
      return widths;
    }, []);

    // Add the header row lengths
    const headerRow =
      dataToExport.length > 0 ? Object.keys(dataToExport[0]) : [];
    headerRow.forEach((header, index) => {
      if (!columnWidths[index] || header.length > columnWidths[index]) {
        columnWidths[index] = header.length;
      }
    });

    // Set the column widths
    ws["!cols"] = columnWidths.map((width) => ({ wch: width + 2 })); // Adding some padding for better readability

    XLSX.utils.book_append_sheet(wb, ws, "Configurador");
    XLSX.writeFile(wb, "configurador.xlsx");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="needs-validation">
                    <Row>
                      <Col md="10">
                        <h4 className="card-title">
                          VER LISTA DE PRODUCTOS COMPLETA
                        </h4>
                      </Col>
                      <Col md="2">
                        <div className="text-right">
                          <Button color="primary" onClick={downloadExcel}>
                            Descargar
                          </Button>
                        </div>
                      </Col>
                      <Col md="12">
                        <hr />
                        <p>
                          <b>Total impresoras:</b>{" "}
                          {dataOriginal.reduce((acc, item) => {
                            if (!item.numeroPartePadre) acc++;
                            return acc;
                          }, 0)}{" "}
                        </p>
                        <br />
                        <Spreadsheet data={data} columnLabels={columnLabels} />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorItemsDetails;
