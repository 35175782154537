import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";



import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

const ConfiguratorNotesDetails = ({ match, history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);



  // this variable will control the if the admin can change the channel or no
  const [disabled, setDisabled] = useState(true);


  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/

  // this represent the id of the channel
  const [id, setId] = useState("");

  const [form, setForm] = useState({
    description: null,
    status: null,
    estado: null
  });

  // this will be used to save the channel before update
  const [channelBeforeUpdate, setNoteBeforeUpdate] = useState({});

  // this function will process the data result of method getProfileNote
  // this function will be process the result of the method getListNote
  const handleGetNoteDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        setId(response.id);
        setForm({
          ...response,
          status: response.estado === 'ACTIVO' ? true : false
        });

        setNoteBeforeUpdate({
            ...response,
            status: response.estado === 'ACTIVO' ? true : false
          });

        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  // this function will be used to get profile of channel by ruc
  const getNote = () => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        const { id } = match.params;

        request(
          {
            url: `/auth/v1/configurador/notes/list/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          handleGetNoteDataResponse
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // i have different question here
    getNote();
    // these function will be used to get all documents of the channel
    //getDocuments();
  }, []);


  const cancelBeforeUpdate = () => {
    setForm({ ...channelBeforeUpdate });

    setDisabled(true);
  };

  const updateNoteDataResponse = (response) => {
    if (response.status && response.errorCode) {
      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push("/configurator-notes-edited");
    }
  };

  const handleSubmit = () => {
    try {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/v1/configurador/notes/register`,
          method: "POST",
          headers: myHeaders,
          body: {...form},
        },
        updateNoteDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleStatusChange = () => {
    if(form.status){
      setForm({...form, status: false, estado: 'INACTIVO'});
    }else{
      setForm({...form, status: true, estado: 'ACTIVO'});
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                  >
                    <h4 className="card-title">DATOS NOTA</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="note-update-descripcion"
                          >
                            Nota
                          </Label> <br />
                          <AvField
                            name="descripcion"
                            type="text"
                            errorMessage="Ingrese la descripción"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="note-update-descripcion"
                            value={form.descripcion}
                            disabled={disabled}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="note-update-position"
                          >
                            Posición
                          </Label> <br />
                          <AvField
                            name="position"
                            type="text"
                            errorMessage="Ingrese un valor"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="note-update-position"
                            value={form.position}
                            disabled={disabled}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mb-3 ">
                        <Label
                          className="form-label"
                          htmlFor="validationCustom98"
                        >
                          Estado Nota 
                        </Label><br />
                        <div className="d-flex"> <AvField
                          name="status"
                          type="text"
                          className="form-control container-fluid"
                          id="validationCustom98"
                          value={form.status ? "Activo" : "Inactivo"}
                          disabled
                        />
                        <div>
                          <button
                            className={`btn ${
                              !form.status ? "btn-primary" : "btn-danger"
                            }`}
                            onClick={() => onHandleStatusChange()}
                            type="button"
                            disabled={disabled}
                          >
                            {!form.status ? (
                              <AiFillCheckCircle />
                            ) : (
                              <AiFillCloseCircle />
                            )}
                          </button>
                        </div></div>
                      </Col>
                    </Row>

                    {disabled ? (
                      <Button
                        className="btn btn-primary-lexmark"
                        type="button"
                        onClick={() => {
                          setDisabled(false);
                        }}
                      >
                        Editar
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="btn btn-primary-danger mx-2"
                          type="button"
                          onClick={cancelBeforeUpdate}
                        >
                          Cancelar
                        </Button>

                        <Button
                          className="btn btn-primary-lexmark"
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorNotesDetails;
