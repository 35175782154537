import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */

const ConfiguratorNotesRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();


  // this will be used to control de component modal

  const handleRedirectToListWhenTheBannerIsCreated = () => {
    history.push(`/banner-list`);
  };

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    descripcion: null,
    // position: null,
    estado: "ACTIVO",
  });


  const handleCreateNoteResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push(`/configurator-notes-created`);
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );



      request(
        {
          url: `/auth/v1/configurador/notes/register`,
          method: "POST",
          headers: myHeaders,
          body: {...form},
        },
        handleCreateNoteResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title">DATOS NOTA</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-note"
                          >
                            Nota
                          </Label>
                          <br />
                          <AvField
                            name="descripcion"
                            type="text"
                            errorMessage="Ingrese la nota"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-note"
                            value={form.descripcion}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      {/* <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-description"
                          >
                            Orden
                          </Label>
                          <br />

                          <AvField
                            name="position"
                            type="number"
                            errorMessage="Ingrese el orden"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-position"
                            value={form.position}
                            min={1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col> */}
                    </Row>

                    <div className="text-right">
                      <br />
                      <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={!form.descripcion}
                    >
                      Crear
                    </Button>

                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfiguratorNotesRegister;
